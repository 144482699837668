import React, { useContext } from 'react';
import Helmet from 'react-helmet';
import styled from 'styled-components';
import { Text } from '@latitude/text';
import { BREAKPOINT, COLOR } from '@latitude/core/utils/constants';
import { AnalyticsContext } from 'latitude-analytics';
import Layout from '../components/layout';

const EappsErrorPage = ({ location }) => {
  const [analytics] = useContext(AnalyticsContext);

  // adding pageData to analytics
  analytics.pageData = {
    page_site: 'gemf-web',
    page_sitecountry: 'nz',
    page_pagetitle: 'eappserror-ocv',
    page_pagetype: 'error-page',
    page_sitesection: 'error',
    page_sitesubsection: 'eappserror-ocv'
  };

  return (
    <Layout location={location}>
      <main className="navigation-spacer" css="background-color:#f8f8f8;">
        <Helmet>
          <title>eApps Error | Gem by Latitude</title>
          <link rel="canonical" href={`${location.href}`} />
          <meta name="description" content="" />
        </Helmet>

        <Page404Content>
          <h1>Sorry, Onecard Visa is no longer accepting applications</h1>
          <img src={require('../images/page-404.svg')} alt="" />
        </Page404Content>
      </main>
    </Layout>
  );
};

export default EappsErrorPage;

const Page404Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 80px 0 100px;

  @media (min-width: ${BREAKPOINT.LG}) {
    padding: 120px 0 140px;
  }

  h1 {
    font-size: 32px;
    font-weight: 600;
    line-height: 1.17;
    letter-spacing: -1px;
    display: block;
    color: ${COLOR.BLACK};
    margin: 0 0 16px;

    @media (min-width: ${BREAKPOINT.LG}) {
      font-size: 48px;
    }
  }

  p {
    @media (min-width: ${BREAKPOINT.LG}) {
      font-size: 18px;
    }
  }

  h1,
  p {
    max-width: 960px;
    text-align: center;
  }

  img {
    margin-top: 40px;
    max-width: 80%;
  }
`;
